// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-templates-band-name-vortex-js": () => import("./../../../src/templates/band-name-vortex.js" /* webpackChunkName: "component---src-templates-band-name-vortex-js" */),
  "component---src-templates-history-page-js": () => import("./../../../src/templates/history-page.js" /* webpackChunkName: "component---src-templates-history-page-js" */),
  "component---src-templates-index-page-js": () => import("./../../../src/templates/index-page.js" /* webpackChunkName: "component---src-templates-index-page-js" */),
  "component---src-templates-page-with-banner-js": () => import("./../../../src/templates/page-with-banner.js" /* webpackChunkName: "component---src-templates-page-with-banner-js" */),
  "component---src-templates-tshirts-page-js": () => import("./../../../src/templates/tshirts-page.js" /* webpackChunkName: "component---src-templates-tshirts-page-js" */)
}

